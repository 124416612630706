(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/product-links/views/product-link.js') >= 0) return;  svs.modules.push('/widgets/product-links/views/product-link.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.product_links=_svs.widget.product_links||{};
_svs.widget.product_links.templates=_svs.widget.product_links.templates||{};
svs.widget.product_links.templates.product_link = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " product-without-name";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"badge",{"name":"ui","hash":{"class":"badge badge-red badge-default badge-large"},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":87}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"badge") || (depth0 != null ? lookupProperty(depth0,"badge") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"badge","hash":{},"data":data,"loc":{"start":{"line":10,"column":71},"end":{"line":10,"column":80}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"product-name bg-"
    + alias4(((helper = (helper = lookupProperty(helpers,"colorClass") || (depth0 != null ? lookupProperty(depth0,"colorClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"colorClass","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":48}}}) : helper)))
    + " notranslate\" itemprop=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayName") || (depth0 != null ? lookupProperty(depth0,"displayName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayName","hash":{},"data":data,"loc":{"start":{"line":13,"column":78},"end":{"line":13,"column":93}}}) : helper)))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideProductNames") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":71}}})) != null ? stack1 : "")
    + "\">\n  <a class=\"product-link js-product-links js-clickable clickable-icon-brand\" href=\""
    + alias3((lookupProperty(helpers,"urlMapping")||(depth0 && lookupProperty(depth0,"urlMapping"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"urlMapping") : depth0),{"name":"urlMapping","hash":{},"data":data,"loc":{"start":{"line":2,"column":83},"end":{"line":2,"column":108}}}))
    + "\" itemprop=\"url\">\n    <div class=\"hidden-lg hidden-xl\">\n      "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":(depth0 != null ? lookupProperty(depth0,"product") : depth0),"size":"300","class":"relative","square":true,"brand":(depth0 != null ? lookupProperty(depth0,"brand") : depth0)},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":85}}}))
    + "\n    </div>\n    <div class=\"visible-lg-inline-block visible-xl-inline-block\">\n      "
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":(depth0 != null ? lookupProperty(depth0,"product") : depth0),"size":"500","class":"relative","square":true,"brand":(depth0 != null ? lookupProperty(depth0,"brand") : depth0)},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":85}}}))
    + "\n    </div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"badge") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideProductNames") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "  </a>\n</div>\n";
},"useData":true});
Handlebars.partials['widget-product_links-product_link'] = svs.widget.product_links.templates.product_link;
})(svs, Handlebars);


 })(window);