(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/product-links/assets/javascripts/product-links.js') >= 0) return;  svs.modules.push('/widgets/product-links/assets/javascripts/product-links.js');
'use strict';

const handleClickProduct = clickEvent => {
  clickEvent.preventDefault();
  const clickedEl = clickEvent.currentTarget;
  const productNameEl = clickedEl.querySelector('.product-name');
  if (!productNameEl) {
    return;
  }
  const productName = productNameEl.textContent;
  const event = {
    action: 'Game Chooser',
    category: 'Navigation',
    opt_label: productName
  };
  const url = clickedEl.getAttribute('href');
  svs.components.analytics.trackEvent(event, () => {
    location.href = url;
  });
};
const init = options => {
  if (!options.$el) {
    return;
  }
  const widgetEl = options.$el.get(0);
  const productElements = Array.prototype.slice.call(widgetEl.querySelectorAll('.js-product-links'));
  productElements.forEach(el => {
    el.addEventListener('click', handleClickProduct);
  });
};
setGlobal('svs.widget.productLinks.init', init);

 })(window);